import { Dialog, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { SaveCancelStack } from "components/buttons/buttonStack";
import { useSnackAlert } from "components/util/snackAlert";
import {
  SetStatusInput,
  Status,
  useSetWorkItemStatusMutation,
} from "graphql/generated";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormContainer, SelectElement } from "react-hook-form-mui";

export const SetWorkItemStatusDialog = (props: {
  open: boolean;
  onClose: () => void;
  workItemID: string;
  currentStatus?: Status;
}) => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const [setStatusMutation, { loading }] = useSetWorkItemStatusMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onError: (error) => {
      showSnackAlert({ error });
    },
    onCompleted: (data) => {
      props.onClose();
    },
  });

  const StatusChanges = {
    [Status.Draft]: Status.Confirmed,
    [Status.Confirmed]: Status.InProgress,
    [Status.InProgress]: Status.Completed,
    [Status.Completed]: Status.Completed,
  };

  const form = useForm<SetStatusInput>({
    defaultValues: {
      workItemID: props.workItemID,
      status: props.currentStatus
        ? StatusChanges[props.currentStatus]
        : undefined,
    },
  });

  useEffect(() => {
    if (props.open) {
      form.reset({
        workItemID: props.workItemID,
        status: props.currentStatus
          ? StatusChanges[props.currentStatus]
          : undefined,
      });
    }
  }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFormSubmit = (data: SetStatusInput) => {
    setStatusMutation({
      variables: {
        input: {
          workItemID: data.workItemID,
          status: data.status,
        },
      },
    });
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>Set Status</DialogTitle>
        <FormContainer formContext={form}>
          <Stack>
            <SelectElement
              name="status"
              label="Status"
              options={(
                Object.values(Status).filter(
                  (status) => status !== props.currentStatus
                ) as Status[]
              ).map((status) => ({ id: status, label: status }))}
            ></SelectElement>
            <SaveCancelStack
              loading={loading}
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onClose}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};
