import { Chip } from "@mui/material";
import { Status } from "../graphql/generated";

export const StatusChip = (props: {
  status: Status;
  size?: "small" | "medium";
}) => {
  switch (props.status) {
    case Status.Draft:
      return <Chip size={props.size} label="Draft" color="statusDraft" />;
    case Status.Confirmed:
      return (
        <Chip size={props.size} label="Confirmed" color="statusConfirmed" />
      );
    case Status.InProgress:
      return (
        <Chip size={props.size} label="In Progress" color="statusInProgress" />
      );
    case Status.Completed:
      return <Chip size={props.size} label="Done" color="statusCompleted" />;
    default:
      return <Chip size={props.size} label="Unknown" color="error" />;
  }
};
