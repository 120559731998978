import { Divider, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

export const DetailGridPanel = (props: { children: any }) => {
  return (
    <Grid xs={12} sm={12} md={6} lg={4}>
      <Paper variant="outlined" square>
        {props.children}
      </Paper>
    </Grid>
  );
};

export const DetailPanelTitle = (props: { title: string; children?: any }) => {
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 1,
          paddingLeft: 2,
        }}
      >
        {props.title}
        {props.children}
      </Typography>
      <Divider />
    </>
  );
};
