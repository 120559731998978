import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Theme } from "theme";
import { ProfilePanel } from "components/panels/profilePanel";

// TODO: Implement changing user metadata

export const SettingsView = () => {
  const isMobile = useMediaQuery(() => Theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 2 }}
      columnSpacing={{ xs: 1, sm: 2 }}
      padding={isMobile ? 1 : 1}
      margin={0}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <ProfilePanel />
    </Grid>
  );
};
