import { Loading } from "./loading";

export const DataSwitch = (props: {
  data: any;
  loading: boolean;
  error: any;
  children: any;
}) => {
  return (
    <>
      {props.data && props.children}
      {props.loading && <Loading />}
      {props.error && <div>Error</div>}
    </>
  );
};
