import { Box, CircularProgress } from "@mui/material";

export const Loading = () => {
  return (
    <Box
      // height="80vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};
