import { Drawer, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { Theme } from "theme";

// On web:
// Show list of items and allow user to select one to view
// Show selected item details on right side of screen

// On mobile:
// Show list of items and allow user to select one to view
// Show selected item details in a fullscreen dialog

const drawerWidth = 240;

export const ListLayout = (props: {
  list: JSX.Element;
  details?: JSX.Element | null;
}) => {
  const isMobile = useMediaQuery(() => Theme.breakpoints.down("sm"));
  const listVisible = !isMobile || !props.details;
  const detailsVisible = !isMobile || props.details;
  return (
    <Box display="flex">
      <Drawer
        variant="persistent"
        open={listVisible}
        sx={{
          width: listVisible ? drawerWidth : 0,

          flexShrink: 0,
          [`& .MuiDrawer-root`]: {
            display: listVisible ? "block" : "none",
          },
          [`& .MuiDrawer-paper`]: {
            width: isMobile ? "100%" : drawerWidth,
            boxSizing: "border-box",
          },
          zIndex: (theme) => theme.zIndex.appBar - 1,
        }}
      >
        <>
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>{props.list}</Box>
        </>
      </Drawer>
      <Box sx={{ flexGrow: 1, display: "flex", height: "100%" }}>
        {detailsVisible &&
          (props.details || (
            // Show filler component when no details are available

            <NoDetails />
          ))}
      </Box>
    </Box>
  );
};

// Filler component to show when no details are available
// Centered text with a message
const NoDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Typography variant="h6">Select an item to view details</Typography>
    </Box>
  );
};
