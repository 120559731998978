import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Loading } from "components/loading";
import { ElementPanel } from "components/panels/elementPanel";
import { GeneralPanel } from "components/panels/generalPanel";
import { useSnackAlert } from "components/util/snackAlert";
import {
  FindWorkItemDetailsQuery,
  Status,
  useFindWorkItemDetailsQuery,
  WorkElementType,
} from "graphql/generated";
import { useParams } from "react-router-dom";
import { Theme } from "theme";

interface Params {
  urlID: string;
}

export const WorkItemDetails = (props: { id?: string }) => {
  const { urlID } = useParams<keyof Params>() as Params;
  const id = props.id || urlID;
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const { data, loading, error } = useFindWorkItemDetailsQuery({
    // context: { permission: AuthPermission.ReadWorkItems },
    variables: { id },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });

  return (
    <>
      {data && <Main workItem={data.workItem} />}
      {loading && <Loading />}
      {error && <div>Error</div>}
      <SnackAlert />
    </>
  );
};

const Main = (props: { workItem: FindWorkItemDetailsQuery["workItem"] }) => {
  const isMobile = useMediaQuery(() => Theme.breakpoints.down("sm"));
  const editable = props.workItem.status === Status.Draft;
  return (
    <>
      {/* Slightly darker backgroud */}
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 2 }}
        columnSpacing={{ xs: 1, sm: 2 }}
        padding={isMobile ? 1 : 1}
        margin={0}
      >
        <GeneralPanel workItem={props.workItem} editable={editable} />
        <ElementPanel
          title="Frames"
          workItemID={props.workItem.id}
          items={props.workItem.workFrames}
          type={WorkElementType.WorkFrame}
          editable={editable}
        />
        <ElementPanel
          title="Mats"
          workItemID={props.workItem.id}
          items={props.workItem.workMats}
          type={WorkElementType.WorkMat}
          editable={editable}
        />
        <ElementPanel
          title="Glasses"
          workItemID={props.workItem.id}
          items={props.workItem.workGlasses}
          type={WorkElementType.WorkGlass}
          editable={editable}
        />
        <ElementPanel
          title="Extras"
          workItemID={props.workItem.id}
          items={props.workItem.workExtras}
          type={WorkElementType.WorkExtra}
          editable={editable}
        />
      </Grid>
    </>
  );
};
