import { Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CreateWorkElementDialog } from "components/dialogs/createWorkElementDialog";
import { DeleteWorkElementDialog } from "components/dialogs/deleteWorkElementDialog";
import { WorkElementList } from "components/lists/workElementList";
import {
  DetailGridPanel,
  DetailPanelTitle,
} from "components/panels/detailGridPanel";
import { FindWorkItemDetailsQuery, WorkElementType } from "graphql/generated";
import { useState } from "react";

export const ElementPanel = (props: {
  title: string;
  workItemID: string;
  items:
    | FindWorkItemDetailsQuery["workItem"]["workFrames"]
    | FindWorkItemDetailsQuery["workItem"]["workMats"]
    | FindWorkItemDetailsQuery["workItem"]["workGlasses"]
    | FindWorkItemDetailsQuery["workItem"]["workExtras"];
  type: WorkElementType;
  editable?: boolean;
}) => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: "",
    name: "",
  });

  return (
    <>
      <DetailGridPanel>
        <DetailPanelTitle title={props.title}>
          {props.editable && (
            <IconButton
              onClick={() => {
                setAddDialogOpen(true);
              }}
            >
              <Add />
            </IconButton>
          )}
        </DetailPanelTitle>
        <WorkElementList
          workItemID={props.workItemID}
          items={props.items}
          editable={props.editable}
          onDelete={(id, name) => {
            setDeleteDialog({ open: true, id: id, name: name });
          }}
        />
      </DetailGridPanel>
      <CreateWorkElementDialog
        open={addDialogOpen && (props.editable || false)}
        workItemID={props.workItemID}
        workElementType={props.type}
        onClose={() => {
          setAddDialogOpen(false);
        }}
      />
      <DeleteWorkElementDialog
        open={deleteDialog.open && (props.editable || false)}
        id={deleteDialog.id}
        name={deleteDialog.name}
        type={props.type}
        onClose={() => {
          setDeleteDialog({ open: false, id: "", name: "" });
        }}
      />
    </>
  );
};
