import { SaveCancelStack } from "components/buttons/buttonStack";
import { ManufactureList } from "components/lists/manufactureList";
import { Loading } from "components/loading";
import { DataSwitch } from "components/util";
import {
  useFindWorkFrameDetailsQuery,
  useListWorkFramesQuery,
} from "graphql/generated";

export const WorkFramePanel = () => {
  const { data, loading, error } = useListWorkFramesQuery({
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <>
      {data && (
        <ManufactureList
          items={data.workFrames}
          dialogContent={FrameManufactureDialog}
        />
      )}
      {loading && <Loading />}
      {error && <div>Error</div>}
    </>
  );
};

const FrameManufactureDialog = (props: { id: string; onClose: () => void }) => {
  const query = useFindWorkFrameDetailsQuery({
    variables: { id: props.id },
    onError: (err) => {
      console.log(err);
    },
  });
  return (
    <DataSwitch data={query.data} loading={query.loading} error={query.error}>
      <div>{}</div>
      <SaveCancelStack
        onSave={() => {
          console.log("onSave");
        }}
        onCancel={props.onClose}
      />
    </DataSwitch>
  );
};
