import {
  Dialog,
  DialogContent,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ListWorkElement } from "../../graphql/types";

export const ManufactureList = (props: {
  items: ListWorkElement;
  dialogContent: (props: { id: string; onClose: () => void }) => JSX.Element;
}) => {
  const [dialog, setDialog] = useState({ open: false, id: "" });

  function handleDialogOpen(id: string) {
    setDialog({ open: true, id: id });
  }

  function handleDialogClose() {
    setDialog({ open: false, id: dialog.id });
  }

  useEffect(() => {
    if (dialog.open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [dialog.open]);

  return (
    <>
      <List>
        {props.items.map((item) => (
          <ListItemButton
            key={item.id}
            divider
            onClick={() => handleDialogOpen(item.id)}
          >
            <ListItemText
              primary={item.workItem.name}
              secondary={item.stockItem.name}
            />
          </ListItemButton>
        ))}
      </List>
      <Dialog open={dialog.open}>
        <DialogContent>
          <props.dialogContent id={dialog.id} onClose={handleDialogClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};
