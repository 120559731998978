import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import { InviteUserForm } from "components/forms/inviteUserForm";
import { useSnackAlert } from "components/util/snackAlert";
import {
  InviteUserInput,
  InviteUserInputSchema,
  useInviteUserMutation,
} from "graphql/generated";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import { DetailGridPanel, DetailPanelTitle } from "./detailGridPanel";

export const UserManagementPanel = () => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();

  const [inviteUserMutation, { loading }] = useInviteUserMutation({
    onCompleted: (data) => {
      showSnackAlert({ message: "Invite sent" });
    },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });

  const form = useForm<InviteUserInput>({
    mode: "onChange",
    resolver: yupResolver(InviteUserInputSchema()),
  });

  const onFormSubmit = (formData: InviteUserInput) => {
    inviteUserMutation({
      variables: {
        input: formData,
      },
    });
  };

  return (
    <>
      <DetailGridPanel>
        <DetailPanelTitle title="Add user" />
        <FormContainer formContext={form}>
          <Stack>
            <InviteUserForm />
            <LoadingButton
              loading={loading}
              onClick={form.handleSubmit(onFormSubmit)}
              variant="contained"
            >
              Invite
            </LoadingButton>
          </Stack>
        </FormContainer>
      </DetailGridPanel>
      <SnackAlert />
    </>
  );
};
