import { useNavigate, useParams } from "react-router-dom";
import { ListLayout } from "components/layouts/listLayout";
import { WorkItemDetails } from "./workItemDetails";
import { WorkItemList } from "./workItemList";

// On web:
// Show list of work items and allow user to select one to view
// Show selected work item on right side of screen

// On mobile:
// Show list of work items and allow user to select one to view
// Show selected work item in a dialog

interface Params {
  id: string;
}

export const WorkItemView = () => {
  const navigate = useNavigate();
  const { id } = useParams<keyof Params>() as Params;
  // const [selectedWorkItemID, setSelectedWorkItemID] = useState("");

  const handleWorkItemClick = (id: string) => {
    navigate("/workItems/" + id);
  };

  return (
    <ListLayout
      list={
        <WorkItemList
          selectedID={id}
          onWorkItemClick={(id) => handleWorkItemClick(id)}
        />
      }
      details={id ? <WorkItemDetails id={id} /> : null}
    />
  );
};
