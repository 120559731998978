import { Dialog, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
  CreateWorkItemInput,
  CreateWorkItemInputSchema,
  ListWorkItemsDocument,
  useCreateWorkItemMutation,
} from "graphql/generated";

import { SaveCancelStack } from "components/buttons/buttonStack";
import { WorkItemGeneralForm } from "components/forms/workItemGeneralForm";
import { useUserUnits } from "components/util/unitConverter";
import { useEffect } from "react";
import { FormContainer } from "react-hook-form-mui";
import { useSnackAlert } from "components/util/snackAlert";

export const CreateWorkItemDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const [createWorkItemMutation, { loading }] = useCreateWorkItemMutation({
    onError: (error) => {
      showSnackAlert({ error });
    },
    onCompleted: (data) => {
      navigate("/workItems/" + data.createWorkItem.id);
      props.onClose();
    },
    refetchQueries: [{ query: ListWorkItemsDocument }],
  });

  const { toSystemUnits } = useUserUnits();

  const validationSchema = CreateWorkItemInputSchema();
  validationSchema.withMutation(() => {
    validationSchema.fields.heightA =
      validationSchema.fields.heightA.transform(toSystemUnits);
    validationSchema.fields.widthA =
      validationSchema.fields.widthA.transform(toSystemUnits);
  });

  const form = useForm<CreateWorkItemInput>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    form.reset({ comment: "" });
  }, [form, props.open]);

  function onFormSubmit(formData: CreateWorkItemInput) {
    console.log("Submit", formData);
    createWorkItemMutation({
      variables: {
        input: formData,
      },
    });
    return false;
  }

  return (
    <>
      <Dialog open={props.open}>
        <FormContainer formContext={form}>
          <Stack spacing={2} mt={2}>
            <WorkItemGeneralForm />
            <SaveCancelStack
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onClose}
              loading={loading}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};
