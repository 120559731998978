import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
// @ts-ignore
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { createUploadLink } from "apollo-upload-client";
import { API_URL } from "config";

export const AuthorizedApolloProvider = (props: { children: any }) => {
  const { getAccessTokenSilently } = useAuth0();

  const httpLink = createUploadLink({
    uri: API_URL, // your URI here...
  });

  const authLink = setContext(async (operation, prevContext) => {
    const token = await getAccessTokenSilently({
      scope: prevContext.permission,
    });
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
