import { Dialog, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { DeleteCancelStack } from "components/buttons/buttonStack";
import { useSnackAlert } from "components/util/snackAlert";
import {
  useRemoveWorkElementMutation,
  WorkElementType,
} from "graphql/generated";

export const DeleteWorkElementDialog = (props: {
  id: string;
  type: WorkElementType;
  open: boolean;
  name: string;
  onClose: () => void;
}) => {
  const cacheKey = {
    [WorkElementType.WorkFrame]: "WorkFrame:",
    [WorkElementType.WorkMat]: "WorkMat:",
    [WorkElementType.WorkGlass]: "WorkGlass:",
    [WorkElementType.WorkExtra]: "WorkExtra:",
  }[props.type];
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const [deleteWorkElement, { loading }] = useRemoveWorkElementMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onCompleted: props.onClose,
    onError: (error) => {
      showSnackAlert({ error });
    },
    update: (cache, { data }) => {
      cache.evict({ id: cacheKey + props.id });
    },
  });
  function onFormSubmit() {
    deleteWorkElement({
      variables: { input: { id: props.id, type: props.type } },
    });
    return false;
  }
  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Are you sure you want to delete {props.name}?</DialogTitle>
        <Stack>
          <DeleteCancelStack
            onDelete={onFormSubmit}
            onCancel={props.onClose}
            loading={loading}
          />
        </Stack>
      </Dialog>
      <SnackAlert />
    </>
  );
};
