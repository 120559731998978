import { Button } from "@mui/material";
import { CreateClientDialog } from "components/dialogs/createClientDialog";
import { useSnackAlert } from "components/util/snackAlert";
import { useListClientsQuery } from "graphql/generated";
import { useState } from "react";
import { AutocompleteElement, useFormContext } from "react-hook-form-mui";

export const SelectClientAutoComplete = () => {
  const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
  const [createClientPreFill, setCreateClientPreFill] = useState<{
    name: string;
    email: string;
    phone: string;
  }>({ name: "", email: "", phone: "" });
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const { data, loading } = useListClientsQuery({
    // context: { permission: AuthPermission.ReadClients },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });

  const { setValue, trigger } = useFormContext();

  return (
    <>
      <AutocompleteElement
        name="clientID"
        label="Client"
        loading={loading}
        options={data?.clients || []}
        matchId
        textFieldProps={{
          onChange: (e) => {
            setCreateClientPreFill({
              name: e.target.value,
              email: "",
              phone: "",
            });
          },
        }}
        autocompleteProps={{
          getOptionLabel: (option) => option.name,
          noOptionsText: (
            <Button
              fullWidth
              variant="outlined"
              onClick={() => setCreateClientDialogOpen(true)}
            >
              Add client
            </Button>
          ),
        }}
      />
      <CreateClientDialog
        open={createClientDialogOpen}
        onClose={() => setCreateClientDialogOpen(false)}
        onCreated={(id) => {
          setValue("clientID", id);
          trigger("clientID");
        }}
        defaultValues={createClientPreFill}
      />
      <SnackAlert />
    </>
  );
};
