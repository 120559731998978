import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, Stack } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";

import { SaveCancelStack } from "components/buttons/buttonStack";
import { WorkItemGeneralForm } from "components/forms/workItemGeneralForm";
import { useUserUnits } from "components/util/unitConverter";
import {
  EditWorkItemInput,
  EditWorkItemInputSchema,
  ListWorkItemsDocument,
  useEditWorkItemMutation,
} from "graphql/generated";
import { useSnackAlert } from "components/util/snackAlert";

// Contents of the work item edit view.
export const EditWorkItemDialog = (props: {
  open: boolean;
  workItem: EditWorkItemInput;
  onClose: () => void;
}) => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const [editWorkItemMutation, { loading }] = useEditWorkItemMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onError: (error) => {
      showSnackAlert({ error });
    },
    onCompleted: props.onClose,
    refetchQueries: [{ query: ListWorkItemsDocument }],
  });

  const { toUserUnits, toSystemUnits } = useUserUnits();

  const validationSchema = EditWorkItemInputSchema();
  validationSchema.withMutation(() => {
    validationSchema.fields.heightA =
      validationSchema.fields.heightA.transform(toSystemUnits);
    validationSchema.fields.widthA =
      validationSchema.fields.widthA.transform(toSystemUnits);
  });

  const form = useForm<EditWorkItemInput>({
    defaultValues: props.workItem,
    resolver: yupResolver(validationSchema),
  });

  // Reset the form when the dialog is opened.
  useEffect(() => {
    form.reset(
      Object.assign({}, props.workItem, {
        heightA: toUserUnits(props.workItem.heightA),
        widthA: toUserUnits(props.workItem.widthA),
      })
    );
  }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  function onFormSubmit(formData: EditWorkItemInput) {
    editWorkItemMutation({
      variables: {
        input: {
          id: formData.id,
          name: formData.name,
          comment: formData.comment,
          heightA: formData.heightA,
          widthA: formData.widthA,
          clientID: formData.clientID,
        },
      },
    });
    return false;
  }

  return (
    <>
      <Dialog open={props.open}>
        <FormContainer formContext={form}>
          <Stack>
            <WorkItemGeneralForm />
            <SaveCancelStack
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onClose}
              loading={loading}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};
