import { Search } from "@mui/icons-material";
import {
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { StatusChip } from "components/statusChip";
import { DataSwitch } from "components/util";
import { useSnackAlert } from "components/util/snackAlert";
import { ListWorkItemsQuery, useListWorkItemsQuery } from "graphql/generated";
import { useState } from "react";
import { CreateWorkItemDialog } from "../../components/dialogs/createWorkItemDialog";

// TODO: Sort

export const WorkItemList = (props: {
  onWorkItemClick: (id: string) => void;
  selectedID?: string;
}) => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const { data, loading, error } = useListWorkItemsQuery({
    // context: { permission: AuthPermission.ReadWorkItems },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const [filterValue, setFilterValue] = useState("");

  const filterFunc = (item: ListWorkItemsQuery["workItems"][0]) => {
    if (filterValue === "") return true;

    const filter = filterValue.toLowerCase();
    if (item.name.toLowerCase().includes(filter)) return true;
    if (item.client.name.toLowerCase().includes(filter)) return true;
    if (item.id.toLowerCase().includes(filter)) return true;
    return false;
  };
  return (
    <>
      <DataSwitch data={data} loading={loading} error={error}>
        <List disablePadding>
          <ListItem>
            <TextField
              value={filterValue}
              variant="standard"
              onChange={(e) => setFilterValue(e.target.value)}
              fullWidth={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
          {data?.workItems.filter(filterFunc).map((item) => (
            <ListItemButton
              key={item.id}
              divider
              onClick={() => props.onWorkItemClick(item.id)}
              selected={item.id === props.selectedID}
              // component={Link}
              // to={item.id}
            >
              {/* Display name and id in same row. Align name left, ID right */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: "6px",
                  }}
                >
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {item.name}
                  </Typography>
                  <Typography variant="body1" sx={{ textAlign: "right" }}>
                    # {item.id}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    mt: "6px",
                  }}
                >
                  <Typography
                    variant="body2"
                    color={"text.secondary"}
                    sx={{ flexGrow: 1 }}
                  >
                    {item.client.name}
                  </Typography>
                  <StatusChip status={item.status} size="small" />
                </Box>
              </Box>
            </ListItemButton>
          ))}
          <ListItem>
            <Button
              onClick={() => setCreateDialogOpen(true)}
              variant="outlined"
              fullWidth={true}
            >
              Create new
            </Button>
          </ListItem>
        </List>
        <CreateWorkItemDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
        />
      </DataSwitch>
      <SnackAlert />
    </>
  );
};
