import * as yup from 'yup'
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
function emptyStringToUndefined(value: number, originalValue: string) { if (typeof originalValue === "string" && originalValue === "") { return undefined; } if (originalValue === null) { return undefined } return value; } 
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Upload: any;
};

export enum AuthPermission {
  EditClients = 'EDIT_CLIENTS',
  EditStockItems = 'EDIT_STOCK_ITEMS',
  EditUsers = 'EDIT_USERS',
  EditWorkItems = 'EDIT_WORK_ITEMS',
  ListWorkElements = 'LIST_WORK_ELEMENTS',
  ReadClients = 'READ_CLIENTS',
  ReadStockItems = 'READ_STOCK_ITEMS',
  ReadUsers = 'READ_USERS',
  ReadWorkItems = 'READ_WORK_ITEMS'
}

export type Client = {
  __typename?: 'Client';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
  workItems: Array<WorkItem>;
};

export type CreateClientInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateWorkExtraInput = {
  extraID: Scalars['ID'];
  workItemID: Scalars['ID'];
};

export type CreateWorkFrameInput = {
  frameID: Scalars['ID'];
  workItemID: Scalars['ID'];
};

export type CreateWorkGlassInput = {
  glassID: Scalars['ID'];
  workItemID: Scalars['ID'];
};

export type CreateWorkItemInput = {
  clientID: Scalars['ID'];
  comment: Scalars['String'];
  heightA: Scalars['Int'];
  name: Scalars['String'];
  widthA: Scalars['Int'];
};

export type CreateWorkMatInput = {
  bottom: Scalars['Int'];
  matID: Scalars['ID'];
  side: Scalars['Int'];
  top: Scalars['Int'];
  workItemID: Scalars['ID'];
};

export type DeleteWorkElementInput = {
  id: Scalars['ID'];
  type: WorkElementType;
};

export type EditWorkItemInput = {
  clientID: Scalars['ID'];
  comment: Scalars['String'];
  discount?: InputMaybe<Scalars['Float']>;
  heightA: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  widthA: Scalars['Int'];
};

export type Extra = StockItem & {
  __typename?: 'Extra';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  stockCode: Scalars['String'];
};

export type Frame = StockItem & {
  __typename?: 'Frame';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  size: Scalars['Int'];
  stockCode: Scalars['String'];
};

export type Glass = StockItem & {
  __typename?: 'Glass';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  stockCode: Scalars['String'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  role: UserRole;
};

export type ListStockItemsFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  type: StockItemType;
};

export type Mat = StockItem & {
  __typename?: 'Mat';
  active: Scalars['Boolean'];
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  stockCode: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addWorkExtra: WorkItem;
  addWorkFrame: WorkItem;
  addWorkGlass: WorkItem;
  addWorkMat: WorkItem;
  createClient: Client;
  createWorkItem: WorkItem;
  deleteWorkItem: WorkItem;
  editWorkItem: WorkItem;
  inviteUser: Scalars['Boolean'];
  removeWorkElement: WorkItem;
  setStatus: WorkItem;
  uploadStockItemList: Scalars['Boolean'];
};


export type MutationAddWorkExtraArgs = {
  input: CreateWorkExtraInput;
};


export type MutationAddWorkFrameArgs = {
  input: CreateWorkFrameInput;
};


export type MutationAddWorkGlassArgs = {
  input: CreateWorkGlassInput;
};


export type MutationAddWorkMatArgs = {
  input: CreateWorkMatInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateWorkItemArgs = {
  input: CreateWorkItemInput;
};


export type MutationDeleteWorkItemArgs = {
  id: Scalars['ID'];
};


export type MutationEditWorkItemArgs = {
  input: EditWorkItemInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationRemoveWorkElementArgs = {
  input: DeleteWorkElementInput;
};


export type MutationSetStatusArgs = {
  input: SetStatusInput;
};


export type MutationUploadStockItemListArgs = {
  input: UploadStockItemListInput;
};

export type Query = {
  __typename?: 'Query';
  clients: Array<Client>;
  stockItems: Array<StockItem>;
  workExtras: Array<WorkExtra>;
  workFrame: WorkFrame;
  workFrames: Array<WorkFrame>;
  workGlasses: Array<WorkGlass>;
  workItem: WorkItem;
  workItems: Array<WorkItem>;
  workMats: Array<WorkMat>;
};


export type QueryStockItemsArgs = {
  input: ListStockItemsFilter;
};


export type QueryWorkFrameArgs = {
  input: Scalars['ID'];
};


export type QueryWorkItemArgs = {
  input: Scalars['ID'];
};

export type SetStatusInput = {
  status: Status;
  workItemID: Scalars['ID'];
};

export enum Status {
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS'
}

export type StatusUpdate = {
  __typename?: 'StatusUpdate';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  status: Status;
  workItem: WorkItem;
  workItemID: Scalars['ID'];
};

export type StockItem = {
  active: Scalars['Boolean'];
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  stockCode: Scalars['String'];
};

export enum StockItemType {
  Extra = 'EXTRA',
  Frame = 'FRAME',
  Glass = 'GLASS',
  Mat = 'MAT'
}

export type UploadStockItemListInput = {
  csv: Scalars['Upload'];
  type: StockItemType;
};

export enum UserRole {
  Admin = 'ADMIN',
  Manufacture = 'MANUFACTURE',
  Sales = 'SALES'
}

export type WorkElement = {
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  price: Scalars['Float'];
  stockItem: StockItem;
  stockItemID: Scalars['ID'];
  workItem: WorkItem;
  workItemID: Scalars['ID'];
};

export enum WorkElementType {
  WorkExtra = 'WORK_EXTRA',
  WorkFrame = 'WORK_FRAME',
  WorkGlass = 'WORK_GLASS',
  WorkMat = 'WORK_MAT'
}

export type WorkExtra = WorkElement & {
  __typename?: 'WorkExtra';
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  price: Scalars['Float'];
  stockItem: Extra;
  stockItemID: Scalars['ID'];
  workItem: WorkItem;
  workItemID: Scalars['ID'];
};

export type WorkFrame = WorkElement & {
  __typename?: 'WorkFrame';
  completed: Scalars['Boolean'];
  completedAt: Scalars['Time'];
  height: Scalars['Int'];
  id: Scalars['ID'];
  price: Scalars['Float'];
  stockItem: Frame;
  stockItemID: Scalars['ID'];
  width: Scalars['Int'];
  workItem: WorkItem;
  workItemID: Scalars['ID'];
};

export type WorkGlass = WorkElement & {
  __typename?: 'WorkGlass';
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  price: Scalars['Float'];
  stockItem: Glass;
  stockItemID: Scalars['ID'];
  workItem: WorkItem;
  workItemID: Scalars['ID'];
};

export type WorkItem = {
  __typename?: 'WorkItem';
  client: Client;
  clientID: Scalars['ID'];
  comment: Scalars['String'];
  createdAt: Scalars['Time'];
  discount: Scalars['Float'];
  heightA: Scalars['Int'];
  heightB: Scalars['Int'];
  heightC: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  priceGross: Scalars['Float'];
  priceNet: Scalars['Float'];
  status: Status;
  statusUpdates: Array<StatusUpdate>;
  updatedAt: Scalars['Time'];
  widthA: Scalars['Int'];
  widthB: Scalars['Int'];
  widthC: Scalars['Int'];
  workExtras: Array<WorkExtra>;
  workFrames: Array<WorkFrame>;
  workGlasses: Array<WorkGlass>;
  workMats: Array<WorkMat>;
};

export type WorkMat = WorkElement & {
  __typename?: 'WorkMat';
  bottom: Scalars['Int'];
  completed: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  price: Scalars['Float'];
  side: Scalars['Int'];
  stockItem: Mat;
  stockItemID: Scalars['ID'];
  top: Scalars['Int'];
  workItem: WorkItem;
  workItemID: Scalars['ID'];
};

export type ListWorkItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkItemsQuery = { __typename?: 'Query', workItems: Array<{ __typename?: 'WorkItem', id: string, name: string, status: Status, client: { __typename?: 'Client', name: string } }> };

export type FindWorkItemDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindWorkItemDetailsQuery = { __typename?: 'Query', workItem: { __typename?: 'WorkItem', id: string, status: Status, name: string, comment: string, heightA: number, widthA: number, heightB: number, widthB: number, heightC: number, widthC: number, priceNet: number, priceGross: number, discount: number, clientID: string, statusUpdates: Array<{ __typename?: 'StatusUpdate', id: string, status: Status, createdAt: any }>, client: { __typename?: 'Client', name: string, email: string, phone: string }, workFrames: Array<{ __typename?: 'WorkFrame', id: string, stockItem: { __typename?: 'Frame', id: string, code: string, name: string } }>, workMats: Array<{ __typename?: 'WorkMat', id: string, top: number, side: number, bottom: number, stockItem: { __typename?: 'Mat', id: string, code: string, name: string } }>, workGlasses: Array<{ __typename?: 'WorkGlass', id: string, stockItem: { __typename?: 'Glass', id: string, code: string, name: string } }>, workExtras: Array<{ __typename?: 'WorkExtra', id: string, stockItem: { __typename?: 'Extra', id: string, code: string, name: string } }> } };

export type WorkItemDimAFragment = { __typename?: 'WorkItem', heightA: number, widthA: number };

export type WorkItemDimBFragment = { __typename?: 'WorkItem', heightB: number, widthB: number };

export type WorkItemDimCFragment = { __typename?: 'WorkItem', heightC: number, widthC: number };

export type WorkMatDimsFragment = { __typename?: 'WorkMat', top: number, side: number, bottom: number };

export type WorkItemPrintDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkItemPrintDetailsQuery = { __typename?: 'Query', workItem: { __typename?: 'WorkItem', id: string, name: string, comment: string, priceNet: number, priceGross: number, clientID: string, heightA: number, widthA: number, client: { __typename?: 'Client', name: string, email: string, phone: string }, workFrames: Array<{ __typename?: 'WorkFrame', id: string, stockItem: { __typename?: 'Frame', id: string, code: string, name: string } }>, workMats: Array<{ __typename?: 'WorkMat', id: string, top: number, side: number, bottom: number, stockItem: { __typename?: 'Mat', id: string, code: string, name: string } }>, workGlasses: Array<{ __typename?: 'WorkGlass', id: string, stockItem: { __typename?: 'Glass', id: string, code: string, name: string } }>, workExtras: Array<{ __typename?: 'WorkExtra', id: string, stockItem: { __typename?: 'Extra', id: string, code: string, name: string } }> } };

export type GetWorkItemPriceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetWorkItemPriceQuery = { __typename?: 'Query', workItem: { __typename?: 'WorkItem', id: string, priceNet: number, priceGross: number } };

export type CreateWorkItemMutationVariables = Exact<{
  input: CreateWorkItemInput;
}>;


export type CreateWorkItemMutation = { __typename?: 'Mutation', createWorkItem: { __typename?: 'WorkItem', id: string } };

export type EditWorkItemMutationVariables = Exact<{
  input: EditWorkItemInput;
}>;


export type EditWorkItemMutation = { __typename?: 'Mutation', editWorkItem: { __typename?: 'WorkItem', id: string, name: string, comment: string, heightA: number, widthA: number, heightB: number, widthB: number, heightC: number, widthC: number } };

export type ListClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'Client', id: string, name: string, email: string, phone: string }> };

export type CreateClientMutationVariables = Exact<{
  input: CreateClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'Client', id: string, name: string, email: string, phone: string } };

export type RemoveWorkElementMutationVariables = Exact<{
  input: DeleteWorkElementInput;
}>;


export type RemoveWorkElementMutation = { __typename?: 'Mutation', removeWorkElement: { __typename?: 'WorkItem', id: string, priceNet: number, priceGross: number, heightB: number, widthB: number, heightC: number, widthC: number, workFrames: Array<{ __typename?: 'WorkFrame', id: string }>, workMats: Array<{ __typename?: 'WorkMat', id: string }>, workGlasses: Array<{ __typename?: 'WorkGlass', id: string }>, workExtras: Array<{ __typename?: 'WorkExtra', id: string }> } };

export type SetWorkItemStatusMutationVariables = Exact<{
  input: SetStatusInput;
}>;


export type SetWorkItemStatusMutation = { __typename?: 'Mutation', setStatus: { __typename?: 'WorkItem', id: string, status: Status, statusUpdates: Array<{ __typename?: 'StatusUpdate', id: string, status: Status, createdAt: any }> } };

export type ListStockItemsQueryVariables = Exact<{
  input: ListStockItemsFilter;
}>;


export type ListStockItemsQuery = { __typename?: 'Query', stockItems: Array<{ __typename?: 'Extra', id: string, code: string, name: string } | { __typename?: 'Frame', id: string, code: string, name: string } | { __typename?: 'Glass', id: string, code: string, name: string } | { __typename?: 'Mat', id: string, code: string, name: string }> };

export type ListWorkFramesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkFramesQuery = { __typename?: 'Query', workFrames: Array<{ __typename?: 'WorkFrame', id: string, height: number, width: number, workItem: { __typename?: 'WorkItem', id: string, name: string }, stockItem: { __typename?: 'Frame', id: string, name: string } }> };

export type FindWorkFrameDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FindWorkFrameDetailsQuery = { __typename?: 'Query', workFrame: { __typename?: 'WorkFrame', id: string, height: number, width: number, workItem: { __typename?: 'WorkItem', id: string, name: string }, stockItem: { __typename?: 'Frame', id: string, name: string } } };

export type AddWorkFrameMutationVariables = Exact<{
  input: CreateWorkFrameInput;
}>;


export type AddWorkFrameMutation = { __typename?: 'Mutation', addWorkFrame: { __typename?: 'WorkItem', id: string, priceNet: number, priceGross: number, heightC: number, widthC: number, workFrames: Array<{ __typename?: 'WorkFrame', id: string, stockItem: { __typename?: 'Frame', id: string, name: string } }> } };

export type ListWorkMatsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListWorkMatsQuery = { __typename?: 'Query', workMats: Array<{ __typename?: 'WorkMat', id: string, workItem: { __typename?: 'WorkItem', id: string, name: string }, stockItem: { __typename?: 'Mat', id: string, name: string } }> };

export type AddWorkMatMutationVariables = Exact<{
  input: CreateWorkMatInput;
}>;


export type AddWorkMatMutation = { __typename?: 'Mutation', addWorkMat: { __typename?: 'WorkItem', id: string, priceNet: number, priceGross: number, heightB: number, widthB: number, heightC: number, widthC: number, workMats: Array<{ __typename?: 'WorkMat', id: string, top: number, side: number, bottom: number, stockItem: { __typename?: 'Mat', id: string, name: string } }> } };

export type AddWorkGlassMutationVariables = Exact<{
  input: CreateWorkGlassInput;
}>;


export type AddWorkGlassMutation = { __typename?: 'Mutation', addWorkGlass: { __typename?: 'WorkItem', id: string, priceNet: number, priceGross: number, workGlasses: Array<{ __typename?: 'WorkGlass', id: string, stockItem: { __typename?: 'Glass', id: string, name: string } }> } };

export type AddWorkExtraMutationVariables = Exact<{
  input: CreateWorkExtraInput;
}>;


export type AddWorkExtraMutation = { __typename?: 'Mutation', addWorkExtra: { __typename?: 'WorkItem', id: string, priceNet: number, priceGross: number, workExtras: Array<{ __typename?: 'WorkExtra', id: string, stockItem: { __typename?: 'Extra', id: string, name: string } }> } };

export type UploadStockItemListMutationVariables = Exact<{
  input: UploadStockItemListInput;
}>;


export type UploadStockItemListMutation = { __typename?: 'Mutation', uploadStockItemList: boolean };

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInput;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: boolean };

export const WorkItemDimAFragmentDoc = gql`
    fragment workItemDimA on WorkItem {
  heightA
  widthA
}
    `;
export const WorkItemDimBFragmentDoc = gql`
    fragment workItemDimB on WorkItem {
  heightB
  widthB
}
    `;
export const WorkItemDimCFragmentDoc = gql`
    fragment workItemDimC on WorkItem {
  heightC
  widthC
}
    `;
export const WorkMatDimsFragmentDoc = gql`
    fragment workMatDims on WorkMat {
  top
  side
  bottom
}
    `;
export const ListWorkItemsDocument = gql`
    query ListWorkItems {
  workItems {
    id
    name
    status
    client {
      name
    }
  }
}
    `;

/**
 * __useListWorkItemsQuery__
 *
 * To run a query within a React component, call `useListWorkItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkItemsQuery(baseOptions?: Apollo.QueryHookOptions<ListWorkItemsQuery, ListWorkItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkItemsQuery, ListWorkItemsQueryVariables>(ListWorkItemsDocument, options);
      }
export function useListWorkItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkItemsQuery, ListWorkItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkItemsQuery, ListWorkItemsQueryVariables>(ListWorkItemsDocument, options);
        }
export type ListWorkItemsQueryHookResult = ReturnType<typeof useListWorkItemsQuery>;
export type ListWorkItemsLazyQueryHookResult = ReturnType<typeof useListWorkItemsLazyQuery>;
export type ListWorkItemsQueryResult = Apollo.QueryResult<ListWorkItemsQuery, ListWorkItemsQueryVariables>;
export const FindWorkItemDetailsDocument = gql`
    query FindWorkItemDetails($id: ID!) {
  workItem(input: $id) {
    id
    status
    statusUpdates {
      id
      status
      createdAt
    }
    name
    comment
    heightA
    widthA
    heightB
    widthB
    heightC
    widthC
    priceNet
    priceGross
    discount
    clientID
    client {
      name
      email
      phone
    }
    workFrames {
      id
      stockItem {
        id
        code
        name
      }
    }
    workMats {
      id
      top
      side
      bottom
      stockItem {
        id
        code
        name
      }
    }
    workGlasses {
      id
      stockItem {
        id
        code
        name
      }
    }
    workExtras {
      id
      stockItem {
        id
        code
        name
      }
    }
  }
}
    `;

/**
 * __useFindWorkItemDetailsQuery__
 *
 * To run a query within a React component, call `useFindWorkItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWorkItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWorkItemDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindWorkItemDetailsQuery(baseOptions: Apollo.QueryHookOptions<FindWorkItemDetailsQuery, FindWorkItemDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindWorkItemDetailsQuery, FindWorkItemDetailsQueryVariables>(FindWorkItemDetailsDocument, options);
      }
export function useFindWorkItemDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindWorkItemDetailsQuery, FindWorkItemDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindWorkItemDetailsQuery, FindWorkItemDetailsQueryVariables>(FindWorkItemDetailsDocument, options);
        }
export type FindWorkItemDetailsQueryHookResult = ReturnType<typeof useFindWorkItemDetailsQuery>;
export type FindWorkItemDetailsLazyQueryHookResult = ReturnType<typeof useFindWorkItemDetailsLazyQuery>;
export type FindWorkItemDetailsQueryResult = Apollo.QueryResult<FindWorkItemDetailsQuery, FindWorkItemDetailsQueryVariables>;
export const WorkItemPrintDetailsDocument = gql`
    query WorkItemPrintDetails($id: ID!) {
  workItem(input: $id) {
    id
    name
    comment
    ...workItemDimA
    priceNet
    priceGross
    clientID
    client {
      name
      email
      phone
    }
    workFrames {
      id
      stockItem {
        id
        code
        name
      }
    }
    workMats {
      id
      stockItem {
        id
        code
        name
      }
      ...workMatDims
    }
    workGlasses {
      id
      stockItem {
        id
        code
        name
      }
    }
    workExtras {
      id
      stockItem {
        id
        code
        name
      }
    }
  }
}
    ${WorkItemDimAFragmentDoc}
${WorkMatDimsFragmentDoc}`;

/**
 * __useWorkItemPrintDetailsQuery__
 *
 * To run a query within a React component, call `useWorkItemPrintDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkItemPrintDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkItemPrintDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkItemPrintDetailsQuery(baseOptions: Apollo.QueryHookOptions<WorkItemPrintDetailsQuery, WorkItemPrintDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkItemPrintDetailsQuery, WorkItemPrintDetailsQueryVariables>(WorkItemPrintDetailsDocument, options);
      }
export function useWorkItemPrintDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkItemPrintDetailsQuery, WorkItemPrintDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkItemPrintDetailsQuery, WorkItemPrintDetailsQueryVariables>(WorkItemPrintDetailsDocument, options);
        }
export type WorkItemPrintDetailsQueryHookResult = ReturnType<typeof useWorkItemPrintDetailsQuery>;
export type WorkItemPrintDetailsLazyQueryHookResult = ReturnType<typeof useWorkItemPrintDetailsLazyQuery>;
export type WorkItemPrintDetailsQueryResult = Apollo.QueryResult<WorkItemPrintDetailsQuery, WorkItemPrintDetailsQueryVariables>;
export const GetWorkItemPriceDocument = gql`
    query GetWorkItemPrice($id: ID!) {
  workItem(input: $id) {
    id
    priceNet
    priceGross
  }
}
    `;

/**
 * __useGetWorkItemPriceQuery__
 *
 * To run a query within a React component, call `useGetWorkItemPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkItemPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkItemPriceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkItemPriceQuery(baseOptions: Apollo.QueryHookOptions<GetWorkItemPriceQuery, GetWorkItemPriceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkItemPriceQuery, GetWorkItemPriceQueryVariables>(GetWorkItemPriceDocument, options);
      }
export function useGetWorkItemPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkItemPriceQuery, GetWorkItemPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkItemPriceQuery, GetWorkItemPriceQueryVariables>(GetWorkItemPriceDocument, options);
        }
export type GetWorkItemPriceQueryHookResult = ReturnType<typeof useGetWorkItemPriceQuery>;
export type GetWorkItemPriceLazyQueryHookResult = ReturnType<typeof useGetWorkItemPriceLazyQuery>;
export type GetWorkItemPriceQueryResult = Apollo.QueryResult<GetWorkItemPriceQuery, GetWorkItemPriceQueryVariables>;
export const CreateWorkItemDocument = gql`
    mutation CreateWorkItem($input: CreateWorkItemInput!) {
  createWorkItem(input: $input) {
    id
  }
}
    `;
export type CreateWorkItemMutationFn = Apollo.MutationFunction<CreateWorkItemMutation, CreateWorkItemMutationVariables>;

/**
 * __useCreateWorkItemMutation__
 *
 * To run a mutation, you first call `useCreateWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkItemMutation, { data, loading, error }] = useCreateWorkItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkItemMutation, CreateWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkItemMutation, CreateWorkItemMutationVariables>(CreateWorkItemDocument, options);
      }
export type CreateWorkItemMutationHookResult = ReturnType<typeof useCreateWorkItemMutation>;
export type CreateWorkItemMutationResult = Apollo.MutationResult<CreateWorkItemMutation>;
export type CreateWorkItemMutationOptions = Apollo.BaseMutationOptions<CreateWorkItemMutation, CreateWorkItemMutationVariables>;
export const EditWorkItemDocument = gql`
    mutation EditWorkItem($input: EditWorkItemInput!) {
  editWorkItem(input: $input) {
    id
    name
    comment
    ...workItemDimA
    ...workItemDimB
    ...workItemDimC
  }
}
    ${WorkItemDimAFragmentDoc}
${WorkItemDimBFragmentDoc}
${WorkItemDimCFragmentDoc}`;
export type EditWorkItemMutationFn = Apollo.MutationFunction<EditWorkItemMutation, EditWorkItemMutationVariables>;

/**
 * __useEditWorkItemMutation__
 *
 * To run a mutation, you first call `useEditWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWorkItemMutation, { data, loading, error }] = useEditWorkItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<EditWorkItemMutation, EditWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditWorkItemMutation, EditWorkItemMutationVariables>(EditWorkItemDocument, options);
      }
export type EditWorkItemMutationHookResult = ReturnType<typeof useEditWorkItemMutation>;
export type EditWorkItemMutationResult = Apollo.MutationResult<EditWorkItemMutation>;
export type EditWorkItemMutationOptions = Apollo.BaseMutationOptions<EditWorkItemMutation, EditWorkItemMutationVariables>;
export const ListClientsDocument = gql`
    query ListClients {
  clients {
    id
    name
    email
    phone
  }
}
    `;

/**
 * __useListClientsQuery__
 *
 * To run a query within a React component, call `useListClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListClientsQuery(baseOptions?: Apollo.QueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
      }
export function useListClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
        }
export type ListClientsQueryHookResult = ReturnType<typeof useListClientsQuery>;
export type ListClientsLazyQueryHookResult = ReturnType<typeof useListClientsLazyQuery>;
export type ListClientsQueryResult = Apollo.QueryResult<ListClientsQuery, ListClientsQueryVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($input: CreateClientInput!) {
  createClient(input: $input) {
    id
    name
    email
    phone
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const RemoveWorkElementDocument = gql`
    mutation RemoveWorkElement($input: DeleteWorkElementInput!) {
  removeWorkElement(input: $input) {
    id
    priceNet
    priceGross
    ...workItemDimB
    ...workItemDimC
    workFrames {
      id
    }
    workMats {
      id
    }
    workGlasses {
      id
    }
    workExtras {
      id
    }
  }
}
    ${WorkItemDimBFragmentDoc}
${WorkItemDimCFragmentDoc}`;
export type RemoveWorkElementMutationFn = Apollo.MutationFunction<RemoveWorkElementMutation, RemoveWorkElementMutationVariables>;

/**
 * __useRemoveWorkElementMutation__
 *
 * To run a mutation, you first call `useRemoveWorkElementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkElementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkElementMutation, { data, loading, error }] = useRemoveWorkElementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWorkElementMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWorkElementMutation, RemoveWorkElementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWorkElementMutation, RemoveWorkElementMutationVariables>(RemoveWorkElementDocument, options);
      }
export type RemoveWorkElementMutationHookResult = ReturnType<typeof useRemoveWorkElementMutation>;
export type RemoveWorkElementMutationResult = Apollo.MutationResult<RemoveWorkElementMutation>;
export type RemoveWorkElementMutationOptions = Apollo.BaseMutationOptions<RemoveWorkElementMutation, RemoveWorkElementMutationVariables>;
export const SetWorkItemStatusDocument = gql`
    mutation SetWorkItemStatus($input: SetStatusInput!) {
  setStatus(input: $input) {
    id
    status
    statusUpdates {
      id
      status
      createdAt
    }
  }
}
    `;
export type SetWorkItemStatusMutationFn = Apollo.MutationFunction<SetWorkItemStatusMutation, SetWorkItemStatusMutationVariables>;

/**
 * __useSetWorkItemStatusMutation__
 *
 * To run a mutation, you first call `useSetWorkItemStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkItemStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkItemStatusMutation, { data, loading, error }] = useSetWorkItemStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWorkItemStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkItemStatusMutation, SetWorkItemStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkItemStatusMutation, SetWorkItemStatusMutationVariables>(SetWorkItemStatusDocument, options);
      }
export type SetWorkItemStatusMutationHookResult = ReturnType<typeof useSetWorkItemStatusMutation>;
export type SetWorkItemStatusMutationResult = Apollo.MutationResult<SetWorkItemStatusMutation>;
export type SetWorkItemStatusMutationOptions = Apollo.BaseMutationOptions<SetWorkItemStatusMutation, SetWorkItemStatusMutationVariables>;
export const ListStockItemsDocument = gql`
    query ListStockItems($input: ListStockItemsFilter!) {
  stockItems(input: $input) {
    id
    code
    name
  }
}
    `;

/**
 * __useListStockItemsQuery__
 *
 * To run a query within a React component, call `useListStockItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStockItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStockItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListStockItemsQuery(baseOptions: Apollo.QueryHookOptions<ListStockItemsQuery, ListStockItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStockItemsQuery, ListStockItemsQueryVariables>(ListStockItemsDocument, options);
      }
export function useListStockItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStockItemsQuery, ListStockItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStockItemsQuery, ListStockItemsQueryVariables>(ListStockItemsDocument, options);
        }
export type ListStockItemsQueryHookResult = ReturnType<typeof useListStockItemsQuery>;
export type ListStockItemsLazyQueryHookResult = ReturnType<typeof useListStockItemsLazyQuery>;
export type ListStockItemsQueryResult = Apollo.QueryResult<ListStockItemsQuery, ListStockItemsQueryVariables>;
export const ListWorkFramesDocument = gql`
    query ListWorkFrames {
  workFrames {
    id
    height
    width
    workItem {
      id
      name
    }
    stockItem {
      id
      name
    }
  }
}
    `;

/**
 * __useListWorkFramesQuery__
 *
 * To run a query within a React component, call `useListWorkFramesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkFramesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkFramesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkFramesQuery(baseOptions?: Apollo.QueryHookOptions<ListWorkFramesQuery, ListWorkFramesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkFramesQuery, ListWorkFramesQueryVariables>(ListWorkFramesDocument, options);
      }
export function useListWorkFramesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkFramesQuery, ListWorkFramesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkFramesQuery, ListWorkFramesQueryVariables>(ListWorkFramesDocument, options);
        }
export type ListWorkFramesQueryHookResult = ReturnType<typeof useListWorkFramesQuery>;
export type ListWorkFramesLazyQueryHookResult = ReturnType<typeof useListWorkFramesLazyQuery>;
export type ListWorkFramesQueryResult = Apollo.QueryResult<ListWorkFramesQuery, ListWorkFramesQueryVariables>;
export const FindWorkFrameDetailsDocument = gql`
    query FindWorkFrameDetails($id: ID!) {
  workFrame(input: $id) {
    id
    height
    width
    workItem {
      id
      name
    }
    stockItem {
      id
      name
    }
  }
}
    `;

/**
 * __useFindWorkFrameDetailsQuery__
 *
 * To run a query within a React component, call `useFindWorkFrameDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWorkFrameDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWorkFrameDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindWorkFrameDetailsQuery(baseOptions: Apollo.QueryHookOptions<FindWorkFrameDetailsQuery, FindWorkFrameDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindWorkFrameDetailsQuery, FindWorkFrameDetailsQueryVariables>(FindWorkFrameDetailsDocument, options);
      }
export function useFindWorkFrameDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindWorkFrameDetailsQuery, FindWorkFrameDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindWorkFrameDetailsQuery, FindWorkFrameDetailsQueryVariables>(FindWorkFrameDetailsDocument, options);
        }
export type FindWorkFrameDetailsQueryHookResult = ReturnType<typeof useFindWorkFrameDetailsQuery>;
export type FindWorkFrameDetailsLazyQueryHookResult = ReturnType<typeof useFindWorkFrameDetailsLazyQuery>;
export type FindWorkFrameDetailsQueryResult = Apollo.QueryResult<FindWorkFrameDetailsQuery, FindWorkFrameDetailsQueryVariables>;
export const AddWorkFrameDocument = gql`
    mutation AddWorkFrame($input: CreateWorkFrameInput!) {
  addWorkFrame(input: $input) {
    id
    priceNet
    priceGross
    ...workItemDimC
    workFrames {
      id
      stockItem {
        id
        name
      }
    }
  }
}
    ${WorkItemDimCFragmentDoc}`;
export type AddWorkFrameMutationFn = Apollo.MutationFunction<AddWorkFrameMutation, AddWorkFrameMutationVariables>;

/**
 * __useAddWorkFrameMutation__
 *
 * To run a mutation, you first call `useAddWorkFrameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkFrameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkFrameMutation, { data, loading, error }] = useAddWorkFrameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkFrameMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkFrameMutation, AddWorkFrameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkFrameMutation, AddWorkFrameMutationVariables>(AddWorkFrameDocument, options);
      }
export type AddWorkFrameMutationHookResult = ReturnType<typeof useAddWorkFrameMutation>;
export type AddWorkFrameMutationResult = Apollo.MutationResult<AddWorkFrameMutation>;
export type AddWorkFrameMutationOptions = Apollo.BaseMutationOptions<AddWorkFrameMutation, AddWorkFrameMutationVariables>;
export const ListWorkMatsDocument = gql`
    query ListWorkMats {
  workMats {
    id
    workItem {
      id
      name
    }
    stockItem {
      id
      name
    }
  }
}
    `;

/**
 * __useListWorkMatsQuery__
 *
 * To run a query within a React component, call `useListWorkMatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkMatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkMatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListWorkMatsQuery(baseOptions?: Apollo.QueryHookOptions<ListWorkMatsQuery, ListWorkMatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkMatsQuery, ListWorkMatsQueryVariables>(ListWorkMatsDocument, options);
      }
export function useListWorkMatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkMatsQuery, ListWorkMatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkMatsQuery, ListWorkMatsQueryVariables>(ListWorkMatsDocument, options);
        }
export type ListWorkMatsQueryHookResult = ReturnType<typeof useListWorkMatsQuery>;
export type ListWorkMatsLazyQueryHookResult = ReturnType<typeof useListWorkMatsLazyQuery>;
export type ListWorkMatsQueryResult = Apollo.QueryResult<ListWorkMatsQuery, ListWorkMatsQueryVariables>;
export const AddWorkMatDocument = gql`
    mutation AddWorkMat($input: CreateWorkMatInput!) {
  addWorkMat(input: $input) {
    id
    priceNet
    priceGross
    ...workItemDimB
    ...workItemDimC
    workMats {
      id
      top
      side
      bottom
      stockItem {
        id
        name
      }
    }
  }
}
    ${WorkItemDimBFragmentDoc}
${WorkItemDimCFragmentDoc}`;
export type AddWorkMatMutationFn = Apollo.MutationFunction<AddWorkMatMutation, AddWorkMatMutationVariables>;

/**
 * __useAddWorkMatMutation__
 *
 * To run a mutation, you first call `useAddWorkMatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkMatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkMatMutation, { data, loading, error }] = useAddWorkMatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkMatMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkMatMutation, AddWorkMatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkMatMutation, AddWorkMatMutationVariables>(AddWorkMatDocument, options);
      }
export type AddWorkMatMutationHookResult = ReturnType<typeof useAddWorkMatMutation>;
export type AddWorkMatMutationResult = Apollo.MutationResult<AddWorkMatMutation>;
export type AddWorkMatMutationOptions = Apollo.BaseMutationOptions<AddWorkMatMutation, AddWorkMatMutationVariables>;
export const AddWorkGlassDocument = gql`
    mutation AddWorkGlass($input: CreateWorkGlassInput!) {
  addWorkGlass(input: $input) {
    id
    priceNet
    priceGross
    workGlasses {
      id
      stockItem {
        id
        name
      }
    }
  }
}
    `;
export type AddWorkGlassMutationFn = Apollo.MutationFunction<AddWorkGlassMutation, AddWorkGlassMutationVariables>;

/**
 * __useAddWorkGlassMutation__
 *
 * To run a mutation, you first call `useAddWorkGlassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkGlassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkGlassMutation, { data, loading, error }] = useAddWorkGlassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkGlassMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkGlassMutation, AddWorkGlassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkGlassMutation, AddWorkGlassMutationVariables>(AddWorkGlassDocument, options);
      }
export type AddWorkGlassMutationHookResult = ReturnType<typeof useAddWorkGlassMutation>;
export type AddWorkGlassMutationResult = Apollo.MutationResult<AddWorkGlassMutation>;
export type AddWorkGlassMutationOptions = Apollo.BaseMutationOptions<AddWorkGlassMutation, AddWorkGlassMutationVariables>;
export const AddWorkExtraDocument = gql`
    mutation AddWorkExtra($input: CreateWorkExtraInput!) {
  addWorkExtra(input: $input) {
    id
    priceNet
    priceGross
    workExtras {
      id
      stockItem {
        id
        name
      }
    }
  }
}
    `;
export type AddWorkExtraMutationFn = Apollo.MutationFunction<AddWorkExtraMutation, AddWorkExtraMutationVariables>;

/**
 * __useAddWorkExtraMutation__
 *
 * To run a mutation, you first call `useAddWorkExtraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkExtraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkExtraMutation, { data, loading, error }] = useAddWorkExtraMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkExtraMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkExtraMutation, AddWorkExtraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddWorkExtraMutation, AddWorkExtraMutationVariables>(AddWorkExtraDocument, options);
      }
export type AddWorkExtraMutationHookResult = ReturnType<typeof useAddWorkExtraMutation>;
export type AddWorkExtraMutationResult = Apollo.MutationResult<AddWorkExtraMutation>;
export type AddWorkExtraMutationOptions = Apollo.BaseMutationOptions<AddWorkExtraMutation, AddWorkExtraMutationVariables>;
export const UploadStockItemListDocument = gql`
    mutation UploadStockItemList($input: UploadStockItemListInput!) {
  uploadStockItemList(input: $input)
}
    `;
export type UploadStockItemListMutationFn = Apollo.MutationFunction<UploadStockItemListMutation, UploadStockItemListMutationVariables>;

/**
 * __useUploadStockItemListMutation__
 *
 * To run a mutation, you first call `useUploadStockItemListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadStockItemListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadStockItemListMutation, { data, loading, error }] = useUploadStockItemListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadStockItemListMutation(baseOptions?: Apollo.MutationHookOptions<UploadStockItemListMutation, UploadStockItemListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadStockItemListMutation, UploadStockItemListMutationVariables>(UploadStockItemListDocument, options);
      }
export type UploadStockItemListMutationHookResult = ReturnType<typeof useUploadStockItemListMutation>;
export type UploadStockItemListMutationResult = Apollo.MutationResult<UploadStockItemListMutation>;
export type UploadStockItemListMutationOptions = Apollo.BaseMutationOptions<UploadStockItemListMutation, UploadStockItemListMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($input: InviteUserInput!) {
  inviteUser(input: $input)
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;

export const AuthPermissionSchema = yup.mixed().oneOf([AuthPermission.EditClients, AuthPermission.EditStockItems, AuthPermission.EditUsers, AuthPermission.EditWorkItems, AuthPermission.ListWorkElements, AuthPermission.ReadClients, AuthPermission.ReadStockItems, AuthPermission.ReadUsers, AuthPermission.ReadWorkItems]);

export function CreateClientInputSchema(): yup.SchemaOf<CreateClientInput> {
  return yup.object({
    email: yup.string().defined(),
    name: yup.string().defined(),
    phone: yup.string().defined()
  })
}

export function CreateWorkExtraInputSchema(): yup.SchemaOf<CreateWorkExtraInput> {
  return yup.object({
    extraID: yup.string().defined(),
    workItemID: yup.string().defined()
  })
}

export function CreateWorkFrameInputSchema(): yup.SchemaOf<CreateWorkFrameInput> {
  return yup.object({
    frameID: yup.string().defined(),
    workItemID: yup.string().defined()
  })
}

export function CreateWorkGlassInputSchema(): yup.SchemaOf<CreateWorkGlassInput> {
  return yup.object({
    glassID: yup.string().defined(),
    workItemID: yup.string().defined()
  })
}

export function CreateWorkItemInputSchema(): yup.SchemaOf<CreateWorkItemInput> {
  return yup.object({
    clientID: yup.string().defined(),
    comment: yup.string().defined(),
    heightA: yup.number().integer().positive().transform(emptyStringToUndefined).defined(),
    name: yup.string().defined(),
    widthA: yup.number().integer().positive().transform(emptyStringToUndefined).defined()
  })
}

export function CreateWorkMatInputSchema(): yup.SchemaOf<CreateWorkMatInput> {
  return yup.object({
    bottom: yup.number().integer().positive().transform(emptyStringToUndefined).defined(),
    matID: yup.string().defined(),
    side: yup.number().integer().positive().transform(emptyStringToUndefined).defined(),
    top: yup.number().integer().positive().transform(emptyStringToUndefined).defined(),
    workItemID: yup.string().defined()
  })
}

export function DeleteWorkElementInputSchema(): yup.SchemaOf<DeleteWorkElementInput> {
  return yup.object({
    id: yup.string().defined(),
    type: WorkElementTypeSchema.defined()
  })
}

export function EditWorkItemInputSchema(): yup.SchemaOf<EditWorkItemInput> {
  return yup.object({
    clientID: yup.string().defined(),
    comment: yup.string().defined(),
    discount: yup.number(),
    heightA: yup.number().integer().positive().transform(emptyStringToUndefined).defined(),
    id: yup.string().defined(),
    name: yup.string().defined(),
    widthA: yup.number().integer().positive().transform(emptyStringToUndefined).defined()
  })
}

export function InviteUserInputSchema(): yup.SchemaOf<InviteUserInput> {
  return yup.object({
    email: yup.string().defined(),
    name: yup.string().defined(),
    role: UserRoleSchema.defined()
  })
}

export function ListStockItemsFilterSchema(): yup.SchemaOf<ListStockItemsFilter> {
  return yup.object({
    active: yup.boolean(),
    type: StockItemTypeSchema.defined()
  })
}

export function SetStatusInputSchema(): yup.SchemaOf<SetStatusInput> {
  return yup.object({
    status: StatusSchema.defined(),
    workItemID: yup.string().defined()
  })
}

export const StatusSchema = yup.mixed().oneOf([Status.Completed, Status.Confirmed, Status.Draft, Status.InProgress]);

export const StockItemTypeSchema = yup.mixed().oneOf([StockItemType.Extra, StockItemType.Frame, StockItemType.Glass, StockItemType.Mat]);

export function UploadStockItemListInputSchema(): yup.SchemaOf<UploadStockItemListInput> {
  return yup.object({
    csv: yup.mixed().defined(),
    type: StockItemTypeSchema.defined()
  })
}

export const UserRoleSchema = yup.mixed().oneOf([UserRole.Admin, UserRole.Manufacture, UserRole.Sales]);

export const WorkElementTypeSchema = yup.mixed().oneOf([WorkElementType.WorkExtra, WorkElementType.WorkFrame, WorkElementType.WorkGlass, WorkElementType.WorkMat]);
