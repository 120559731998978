import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

const UNIT_KEY = "frame-front/user_metadata.units";
const DEFAULT_UNITS = "mm";

const conversionFactors: {
  [key: string]: number;
} = {
  mm: 1,
  cm: 10,
  m: 1000,
};

export function useUserUnits() {
  const { getIdTokenClaims } = useAuth0();
  const [userUnits, setUserUnits] = useState(DEFAULT_UNITS);

  useEffect(() => {
    const getUserUnits = async () => {
      const claims = await getIdTokenClaims();
      if (!claims) {
        setUserUnits(DEFAULT_UNITS);
        return;
      }
      if (!claims[UNIT_KEY]) {
        setUserUnits(DEFAULT_UNITS);
        return;
      }
      if (!conversionFactors[claims[UNIT_KEY]]) {
        setUserUnits(DEFAULT_UNITS);
        return;
      }
      setUserUnits(claims[UNIT_KEY]);
    };
    getUserUnits();
  }, [getIdTokenClaims, setUserUnits]);

  const toUserUnits = (value: number) => {
    var converted = value / conversionFactors[userUnits];
    // console.log("toUserUnits", value, "mm", converted, userUnits);
    return Math.round(converted * 100) / 100;
  };

  const toSystemUnits = (value: number) => {
    var converted = value * conversionFactors[userUnits];
    // console.log("toSystemUnits", value, userUnits, converted, "mm");
    return Math.round(converted * 100) / 100;
  };

  return { userUnits, toUserUnits, toSystemUnits };
}
