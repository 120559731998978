import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, FormHelperText, Stack } from "@mui/material";
import { Box } from "@mui/system";
import {
  DetailGridPanel,
  DetailPanelTitle,
} from "components/panels/detailGridPanel";
import { useSnackAlert } from "components/util/snackAlert";
import {
  ListStockItemsDocument,
  StockItemType,
  UploadStockItemListInput,
  UploadStockItemListInputSchema,
  useUploadStockItemListMutation,
} from "graphql/generated";
import { useForm } from "react-hook-form";
import { FormContainer, SelectElement } from "react-hook-form-mui";

export const StockItemUpload = () => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();

  const form = useForm<UploadStockItemListInput>({
    mode: "onChange",
    resolver: yupResolver(UploadStockItemListInputSchema()),
    defaultValues: {
      type: undefined,
    },
  });

  const [uploadStockItemListMutation, { loading }] =
    useUploadStockItemListMutation({
      // context: { permission: AuthPermission.EditStockItems },
      onCompleted: (data) => {
        form.reset();
        showSnackAlert({ message: "Stock items uploaded" });
      },
      onError: (error) => {
        showSnackAlert({ error });
      },
    });

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      form.setValue("csv", file);
      form.trigger("csv");
      e.target.value = "";
    }
  };

  const onFormSubmit = (formData: UploadStockItemListInput) => {
    uploadStockItemListMutation({
      variables: {
        input: formData,
      },
      refetchQueries: [
        {
          query: ListStockItemsDocument,
          variables: {
            input: {
              type: formData.type,
              active: true,
            },
          },
        },
      ],
    });
  };

  const selectOptions = [
    { id: StockItemType.Frame, label: "Frames" },
    { id: StockItemType.Mat, label: "Mats" },
    { id: StockItemType.Glass, label: "Glasses" },
    { id: StockItemType.Extra, label: "Extras" },
  ];
  return (
    <>
      <DetailGridPanel>
        <DetailPanelTitle title="Upload" />
        <FormContainer formContext={form}>
          <Stack>
            <SelectElement
              name="type"
              label="Article Type"
              options={selectOptions}
            />

            <Box>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                color={form.formState.errors.csv ? "error" : "primary"}
              >
                {form.watch("csv")?.name || "Select CSV file"}
                <input
                  // {...form.register("csv")}
                  type="file"
                  // name="csv"
                  hidden
                  accept=".csv"
                  onChange={(e) => onFileChange(e)}
                />
              </Button>
              <FormHelperText error sx={{ marginLeft: "14px" }}>
                {form.formState.errors.csv?.message?.toString()}
              </FormHelperText>
            </Box>
            <LoadingButton
              loading={loading}
              variant="contained"
              component="label"
              onClick={() =>
                form.handleSubmit(onFormSubmit, (err) => console.log(err))()
              }
            >
              Upload
            </LoadingButton>
          </Stack>
        </FormContainer>
      </DetailGridPanel>
      <SnackAlert />
    </>
  );
};
