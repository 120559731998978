import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { UserManagementPanel } from "components/panels/userManagementPanel";
import { Theme } from "theme";
import { StockItemUpload } from "components/panels/stockItemUpload";

export const AdminView = () => {
  const isMobile = useMediaQuery(() => Theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 2 }}
      columnSpacing={{ xs: 1, sm: 2 }}
      padding={isMobile ? 1 : 1}
      margin={0}
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <StockItemUpload />
      <UserManagementPanel />
    </Grid>
  );
};
