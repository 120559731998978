import { Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";

export const SaveCancelStack = (props: {
  onSave: () => void;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <Stack direction="row" padding={0}>
      <Button fullWidth={true} variant="outlined" onClick={props.onCancel}>
        Cancel
      </Button>
      <LoadingButton
        fullWidth={true}
        variant="contained"
        endIcon={<Save />}
        loading={props.loading}
        onClick={props.onSave}
        disabled={props.disabled}
      >
        Save
      </LoadingButton>
    </Stack>
  );
};

export const DeleteCancelStack = (props: {
  onDelete: () => void;
  onCancel: () => void;
  loading: boolean;
}) => {
  return (
    <Stack direction="row" padding={0}>
      <Button fullWidth={true} variant="outlined" onClick={props.onCancel}>
        Cancel
      </Button>
      <LoadingButton
        loading={props.loading}
        color="error"
        fullWidth={true}
        variant="contained"
        onClick={props.onDelete}
      >
        Delete
      </LoadingButton>
    </Stack>
  );
};
