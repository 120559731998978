import { UserRole } from "graphql/generated";
import { AutocompleteElement, TextFieldElement } from "react-hook-form-mui";

const roles = Object.values(UserRole);

export const InviteUserForm = (props: {}) => {
  return (
    <>
      <TextFieldElement name="name" label="Name" fullWidth />
      <TextFieldElement name="email" label="Email" fullWidth />
      <AutocompleteElement
        name="role"
        label="Role"
        options={roles}
        autocompleteProps={{
          isOptionEqualToValue: (option, value) => option === value,
        }}
      />
    </>
  );
};
