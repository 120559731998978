import { InputAdornment } from "@mui/material";
import { Stack } from "@mui/system";
import { useUserUnits } from "components/util/unitConverter";
import { TextFieldElement } from "react-hook-form-mui";
import { SelectClientAutoComplete } from "./formElements/selectClientAutocomplete";

export const WorkItemGeneralForm = (props: { disabled?: boolean }) => {
  const { userUnits } = useUserUnits();
  const disabled = props.disabled || false;
  return (
    <>
      <TextFieldElement
        name="name"
        label="Name"
        disabled={disabled}
        helperText="Please enter short description"
      />
      <SelectClientAutoComplete />
      <TextFieldElement
        name="comment"
        label="Comment"
        disabled={disabled}
        multiline
        rows={5}
        //maxRows={5}
      />
      <Stack padding={0} direction="row">
        <TextFieldElement
          name="heightA"
          label="Height"
          inputMode="numeric"
          disabled={disabled}
          helperText="Enter height in millimeters"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{userUnits}</InputAdornment>
            ),
          }}
        />
        <TextFieldElement
          name="widthA"
          label="Width"
          inputMode="numeric"
          disabled={disabled}
          helperText="Enter width in millimeters"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{userUnits}</InputAdornment>
            ),
          }}
        />
      </Stack>
    </>
  );
};
