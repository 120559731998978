import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AuthorizedApolloProvider } from "apollo";
import App from "App";
import { Loading } from "components/loading";
import { NavBarLayout } from "components/navigation/navBar";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
} from "react-router-dom";
import { AdminView } from "views/administration/adminView";
import { SettingsView } from "views/settings/settingsView";
import { ManufactureView } from "./views/manufacture/manufactureView";
import { WorkItemView } from "./views/workItem/workItemView";

const ProtectedLayout = () => {
  const Comp = withAuthenticationRequired(Outlet, {
    onRedirecting: () => <Loading />,
  });
  return <Comp />;
};

const ApolloProviderLayout = () => {
  return (
    <AuthorizedApolloProvider>
      <Outlet />
    </AuthorizedApolloProvider>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProtectedLayout />}>
      <Route path="/" element={<App />}>
        <Route index element={<Navigate to="workitems" />} />
        <Route element={<NavBarLayout />}>
          <Route path="settings" element={<SettingsView />} />
          {/* Routes with API requirement */}
          <Route element={<ApolloProviderLayout />}>
            <Route path="workitems">
              <Route index element={<WorkItemView />} />
              <Route path=":id" element={<WorkItemView />} />
            </Route>
            <Route path="manufacture">
              <Route path="" element={<ManufactureView />} />
            </Route>
            <Route path="admin" element={<AdminView />}></Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);
