import { createTheme, PaletteColorOptions } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";
import { Status } from "graphql/generated";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
export const Theme = createTheme({
  palette: {
    primary: {
      main: "#455a64",
      light: "#718792",
      dark: "#1c313a",
    },
    secondary: {
      main: "#ffca28",
      light: "#fffd61",
      dark: "#c79a00",
    },
    statusDraft: createColor("#bdbdbd"),
    statusConfirmed: createColor("#64b5f6"),
    statusInProgress: createColor("#ffca28"),
    statusCompleted: createColor("#81c784"),
  },
  //spacing: 2,
  components: {
    MuiStack: {
      defaultProps: {
        spacing: 2,
        padding: 2,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        autoComplete: "off",
        type: "text",
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface CustomPalette {
    statusDraft: PaletteColorOptions;
    statusConfirmed: PaletteColorOptions;
    statusInProgress: PaletteColorOptions;
    statusCompleted: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    statusDraft: true;
    statusConfirmed: true;
    statusInProgress: true;
    statusCompleted: true;
  }
}

declare module "@mui/lab/TimelineDot" {
  interface TimelineDotPropsColorOverrides {
    statusDraft: true;
    statusConfirmed: true;
    statusInProgress: true;
    statusCompleted: true;
  }
}

export const StatusColor = (status: Status) => {
  switch (status) {
    case Status.Draft:
      return "statusDraft";
    case Status.Confirmed:
      return "statusConfirmed";
    case Status.InProgress:
      return "statusInProgress";
    case Status.Completed:
      return "statusCompleted";
    default:
      return "error";
  }
};
