import { gql } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { SaveCancelStack } from "components/buttons/buttonStack";
import { NewClientForm } from "components/forms/newClientForm";
import { useSnackAlert } from "components/util/snackAlert";
import {
  CreateClientInput,
  CreateClientInputSchema,
  useCreateClientMutation,
} from "graphql/generated";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";

export const CreateClientDialog = (props: {
  open: boolean;
  onClose: () => void;
  onCreated?: (id: string) => void;
  defaultValues?: CreateClientInput;
}) => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const [createClientMutation, { loading }] = useCreateClientMutation({
    // context: { permission: AuthPermission.EditClients },
    onCompleted: (data) => {
      props.onCreated?.(data.createClient.id);
      props.onClose();
    },

    onError: (error) => {
      showSnackAlert({ error });
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          clients(existingClients = []) {
            const newClientRef = cache.writeFragment({
              data: data?.createClient,
              fragment: gql`
                fragment NewClient on Client {
                  id
                  name
                  email
                  phone
                }
              `,
            });
            return [...existingClients, newClientRef];
          },
        },
      });
    },
  });

  const form = useForm<CreateClientInput>({
    mode: "onChange",
    resolver: yupResolver(CreateClientInputSchema()),
  });

  useEffect(() => {
    if (props.open) {
      form.reset(props.defaultValues);
    }
  }, [form, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  function onFormSubmit(data: CreateClientInput) {
    createClientMutation({ variables: { input: data } });
    return false;
  }

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Add client</DialogTitle>
        <FormContainer formContext={form}>
          <Stack>
            <NewClientForm />
            <SaveCancelStack
              loading={loading}
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onClose}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};
