import { TextFieldElement } from "react-hook-form-mui";

export const NewClientForm = (props: {}) => {
  return (
    <>
      <TextFieldElement name="name" label="Name" fullWidth />
      <TextFieldElement name="email" label="Email" fullWidth />
      <TextFieldElement name="phone" label="Phone" fullWidth />
    </>
  );
};
