import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DataSwitch } from "components/util";
import { useWorkItemPrintDetailsQuery } from "graphql/generated";

export const WorkItemClientPrint = (props: { workItemID: string }) => {
  const { data, loading, error } = useWorkItemPrintDetailsQuery({
    variables: { id: props.workItemID },
    onError: (err) => {
      console.log(err);
    },
  });
  return (
    <DataSwitch data={data} loading={loading} error={error}>
      {data && (
        <Grid container>
          <Grid xs={12}>
            <Typography variant="h6">Müüja</Typography>
            <Typography variant="body1">Galerii-G</Typography>
            <Typography variant="body1">Vabaduse väljak 6</Typography>
            <Typography variant="body1">info@galeriig.ee</Typography>
            <Typography variant="body1">+372 555 5555</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">Klient</Typography>
            <Typography variant="body1">{data.workItem.client.name}</Typography>
            <Typography variant="body1">
              {data.workItem.client.phone}
            </Typography>
            <Typography variant="body1">
              {data.workItem.client.email}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">Üldinfo</Typography>
            <Typography variant="body1">{data.workItem.name}</Typography>
            <Typography variant="body1">
              {data.workItem.heightA} × {data.workItem.widthA} cm
            </Typography>
            <Typography variant="body1">{data.workItem.comment}</Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">Tooted</Typography>
            {data.workItem.workFrames.map((workFrame) => (
              <Typography variant="body1" key={"frame" + workFrame.id}>
                Raam {workFrame.stockItem.code}
                {workFrame.stockItem.name}
              </Typography>
            ))}
            {data.workItem.workMats.map((workMat) => (
              <Typography variant="body1" key={"mat" + workMat.id}>
                Paspartuu {workMat.stockItem.code} {workMat.stockItem.name}{" "}
                {workMat.top}×{workMat.side}×{workMat.bottom}mm
              </Typography>
            ))}
            {data.workItem.workGlasses.map((workGlass) => (
              <Typography variant="body1" key={"glass" + workGlass.id}>
                {workGlass.stockItem.name}
              </Typography>
            ))}
            {data.workItem.workExtras.map((workExtra) => (
              <Typography variant="body1" key={"extra" + workExtra.id}>
                {workExtra.stockItem.name}
              </Typography>
            ))}
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6">Hind</Typography>
            <Typography variant="body1">
              {" "}
              Hind: {Math.round(data.workItem.priceNet * 100) / 100} €
            </Typography>
            <Typography variant="body1">
              KM:{" "}
              {Math.round(
                (data.workItem.priceGross - data.workItem.priceNet) * 100
              ) / 100}{" "}
              €
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Kokku: {Math.round(data.workItem.priceGross * 100) / 100} €
            </Typography>
          </Grid>
        </Grid>
      )}
    </DataSwitch>
  );
};
