import { Delete } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { useUserUnits } from "components/util/unitConverter";
import { FindWorkItemDetailsQuery } from "graphql/generated";

export const WorkElementList = (props: {
  workItemID: string;
  items:
    | FindWorkItemDetailsQuery["workItem"]["workFrames"]
    | FindWorkItemDetailsQuery["workItem"]["workMats"]
    | FindWorkItemDetailsQuery["workItem"]["workGlasses"]
    | FindWorkItemDetailsQuery["workItem"]["workExtras"];
  onDelete: (id: string, name: string) => void;
  editable?: boolean;
}) => {
  return (
    <List disablePadding>
      {props.items.map((item) => (
        <ListItem key={item.id} divider>
          <ListElementText item={item} />
          {props.editable && (
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => props.onDelete(item.id, item.stockItem.name)}
              >
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      ))}
    </List>
  );
};

const ListElementText = (props: {
  item:
    | FindWorkItemDetailsQuery["workItem"]["workFrames"][0]
    | FindWorkItemDetailsQuery["workItem"]["workMats"][0]
    | FindWorkItemDetailsQuery["workItem"]["workGlasses"][0]
    | FindWorkItemDetailsQuery["workItem"]["workExtras"][0];
}) => {
  const { toUserUnits, userUnits } = useUserUnits();
  switch (props.item.__typename) {
    case "WorkFrame":
      return (
        <ListItemText
          primary={props.item.stockItem.code + " " + props.item.stockItem.name}
        />
      );
    case "WorkGlass":
      return <ListItemText primary={props.item.stockItem.name} />;
    case "WorkMat":
      return (
        <ListItemText
          primary={props.item.stockItem.name}
          secondary={
            toUserUnits(props.item.top) +
            " - " +
            toUserUnits(props.item.side) +
            " - " +
            toUserUnits(props.item.bottom) +
            " " +
            userUnits
          }
        />
      );
    case "WorkExtra":
      return <ListItemText primary={props.item.stockItem.name} />;
    default:
      return <></>;
  }
};
