import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useState } from "react";

export function useSnackAlert() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: "",
    severity: "success",
  });

  const showSnackAlert = (args: {
    error?: Error;
    message?: string;
    severity?: AlertColor;
  }) => {
    const { error, message, severity } = args;
    if (error) {
      setAlertContent({ message: error.message, severity: "error" });
    } else if (message) {
      setAlertContent({ message, severity: severity || "success" });
    }
    setSnackbarOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const SnackAlert = () => {
    return (
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertContent.severity}
          sx={{ width: "100%" }}
        >
          {alertContent.message}
        </Alert>
      </Snackbar>
    );
  };

  return {
    SnackAlert,
    showSnackAlert,
  };
}
