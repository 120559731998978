import { ThemeProvider } from "@mui/material/styles";
//import CssBaseline from "@mui/material/CssBaseline";
import { Container, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Theme } from "theme";

function App() {
  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        backgroundColor: (theme) => theme.palette.grey[100],
      }}
    >
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Outlet />
      </ThemeProvider>
    </Container>
  );
}

export default App;
