import { MoreHoriz } from "@mui/icons-material";
import { Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { EditWorkItemDialog } from "components/dialogs/editWorkItemDialog";
import { SetWorkItemStatusDialog } from "components/dialogs/setWorkItemStatusDialog";
import {
  DetailGridPanel,
  DetailPanelTitle,
} from "components/panels/detailGridPanel";
import { WorkItemClientPrint } from "components/print/workItemClientPrint";
import { StatusChip } from "components/statusChip";
import { useUserUnits } from "components/util/unitConverter";
import { FindWorkItemDetailsQuery } from "graphql/generated";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

// TODO: Üldmõõt lisaks töö mõõdule

// TODO: Display workitem ID

const ml = 2;

export const GeneralPanel = (props: {
  workItem: FindWorkItemDetailsQuery["workItem"];
  editable?: boolean;
}) => {
  const [editDialgoOpen, setEditDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [moreMenuAnchor, setMoreMenuAnchor] = useState<null | HTMLElement>(
    null
  );

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { userUnits, toUserUnits } = useUserUnits();
  return (
    <>
      <DetailGridPanel>
        <DetailPanelTitle title="General">
          <IconButton
            onClick={(event) => setMoreMenuAnchor(event.currentTarget)}
          >
            <MoreHoriz />
          </IconButton>
        </DetailPanelTitle>
        {/* Name */}
        <Typography variant="body1" margin={2}>
          {props.workItem.name}
        </Typography>
        {/* Comment */}
        {props.workItem.comment && (
          <>
            <Divider />
            <Typography variant="body1" margin={2}>
              {props.workItem.comment}
            </Typography>
          </>
        )}
        {/* Dimensions */}
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Typography variant="caption" ml={ml}>
          Dimensions:
        </Typography>
        <Typography variant="body1" ml={ml}>
          {"A: "}
          {toUserUnits(props.workItem.heightA)} ×{" "}
          {toUserUnits(props.workItem.widthA)} {userUnits}
        </Typography>
        <Typography variant="body1" ml={ml}>
          {"B: "}
          {toUserUnits(props.workItem.heightB)} ×{" "}
          {toUserUnits(props.workItem.widthB)} {userUnits}
        </Typography>
        <Typography variant="body1" ml={ml}>
          {"C: "}
          {toUserUnits(props.workItem.heightC)} ×{" "}
          {toUserUnits(props.workItem.widthC)} {userUnits}
        </Typography>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Typography variant="caption" ml={ml}>
          Price:
        </Typography>
        <Typography variant="body1" ml={ml}>
          Net: {Math.round(props.workItem.priceNet * 100) / 100} €
        </Typography>
        <Typography variant="body1" ml={ml}>
          VAT:{" "}
          {Math.round(
            (props.workItem.priceGross - props.workItem.priceNet) * 100
          ) / 100}{" "}
          €
        </Typography>
        <Typography variant="body1" ml={ml}>
          Gross: {Math.round(props.workItem.priceGross * 100) / 100} €
        </Typography>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Typography variant="caption" ml={ml}>
          Status:
        </Typography>
        <Box margin={2}>
          <StatusChip status={props.workItem.status} />
        </Box>
      </DetailGridPanel>
      <Menu
        anchorEl={moreMenuAnchor}
        open={Boolean(moreMenuAnchor)}
        onClose={() => setMoreMenuAnchor(null)}
      >
        <MenuItem
          onClick={() => {
            setStatusDialogOpen(true);
            setMoreMenuAnchor(null);
          }}
        >
          Set status
        </MenuItem>
        <MenuItem
          onClick={() => {
            handlePrint();
            setMoreMenuAnchor(null);
          }}
        >
          Print
        </MenuItem>
        {props.editable && (
          <MenuItem
            onClick={() => {
              setEditDialogOpen(true);
              setMoreMenuAnchor(null);
            }}
          >
            Edit
          </MenuItem>
        )}
      </Menu>
      <SetWorkItemStatusDialog
        workItemID={props.workItem.id}
        currentStatus={props.workItem.status}
        open={statusDialogOpen}
        onClose={() => setStatusDialogOpen(false)}
      />
      <EditWorkItemDialog
        workItem={props.workItem}
        open={editDialgoOpen}
        onClose={() => setEditDialogOpen(false)}
      />
      <Box style={{ display: "none" }}>
        <Box ref={componentRef} margin={2}>
          <WorkItemClientPrint workItemID={props.workItem.id} />
        </Box>
      </Box>
    </>
  );
};
