import { useAuth0 } from "@auth0/auth0-react";
import {
  AdminPanelSettings,
  CropOriginal,
  Logout,
  Menu,
  PrecisionManufacturing,
  Settings,
} from "@mui/icons-material";
import {
  AppBar,
  Breadcrumbs,
  Drawer,
  IconButton,
  Link as MuiLink,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import useBreadcrumbs from "use-react-router-breadcrumbs";

export const NavBarLayout = () => {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
};

export const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const breadcrumbs = useBreadcrumbs();
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(true)}
          >
            <Menu />
          </IconButton>
          <Breadcrumbs color="inherit">
            {breadcrumbs.map(({ breadcrumb, match }, index) => (
              <MuiLink
                key={index}
                color="inherit"
                underline="hover"
                component={Link}
                to={match.pathname}
              >
                {breadcrumb}
              </MuiLink>
            ))}
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerContent onClose={() => setDrawerOpen(false)} />
      </Drawer>
    </>
  );
};

const DrawerContent = (props: { onClose: () => void }) => {
  const navigate = useNavigate();

  const { logout } = useAuth0();

  function navigateAndClose(path: string) {
    navigate(path);
    props.onClose();
  }

  return (
    <List>
      <ListItemButton key={0} onClick={() => navigateAndClose("/workItems")}>
        <ListItemIcon>
          <CropOriginal />
        </ListItemIcon>
        <ListItemText primary="Workitems" />
      </ListItemButton>
      <ListItemButton key={1} onClick={() => navigateAndClose("/manufacture")}>
        <ListItemIcon>
          <PrecisionManufacturing />
        </ListItemIcon>
        <ListItemText primary="Manufacture" />
      </ListItemButton>
      <ListItemButton key={2} onClick={() => navigateAndClose("/settings")}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItemButton>
      <ListItemButton key={3} onClick={() => navigateAndClose("/admin")}>
        <ListItemIcon>
          <AdminPanelSettings />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItemButton>
      <ListItemButton key={4} onClick={() => logout()}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};
