import { InputAdornment } from "@mui/material";
import { useUserUnits } from "components/util/unitConverter";
import { StockItemType } from "graphql/generated";
import { TextFieldElement } from "react-hook-form-mui";
import { SelectStockItemAutoComplete } from "./formElements/selectStockItemAutocomplete";

export const CreateWorkMatForm = () => {
  const { userUnits } = useUserUnits();
  return (
    <>
      <TextFieldElement
        name="top"
        label="Top"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{userUnits}</InputAdornment>
          ),
        }}
      />
      <TextFieldElement
        name="side"
        label="Side"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{userUnits}</InputAdornment>
          ),
        }}
      />
      <TextFieldElement
        name="bottom"
        label="Bottom"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{userUnits}</InputAdornment>
          ),
        }}
      />
      <SelectStockItemAutoComplete stockItemType={StockItemType.Mat} />
    </>
  );
};
