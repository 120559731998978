import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import {
  DetailGridPanel,
  DetailPanelTitle,
} from "components/panels/detailGridPanel";

export const ProfilePanel = () => {
  const { user } = useAuth0();
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     console.log("Authenticated");
  //     console.log(user);
  //     getIdTokenClaims().then((claims) => {
  //       console.log("Claims: ");
  //       console.log(claims);
  //     });
  //   } else {
  //     console.log("Not authenticated");
  //   }
  // }, [isAuthenticated, user, getIdTokenClaims]);

  return (
    <DetailGridPanel>
      <DetailPanelTitle title="Profile" />
      {user && (
        <>
          <Typography variant="h6">Name: {user.name}</Typography>
          <Typography variant="h6">Email: {user.email}</Typography>
          <Typography variant="h6">Username: {user.nickname}</Typography>
          <Typography variant="h6">Picture: {user.picture}</Typography>
        </>
      )}
    </DetailGridPanel>
  );
};
