import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { WorkFramePanel } from "./workFrames";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

export const ManufactureView = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index: number) => {
  //   setValue(index);
  // };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <AppBar
          position="fixed"
          color="primary"
          sx={{ top: "auto", bottom: 0 }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Works" />
            <Tab label="Frames" />
            <Tab label="Mats" />
            <Tab label="Glasses" />
          </Tabs>
        </AppBar>
        {/* <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      > */}
      </Box>
      <TabPanel value={value} index={0} dir={theme.direction}>
        WorkItems
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <WorkFramePanel />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        Item Three
      </TabPanel>
      {/* </SwipeableViews> */}
    </>
  );
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};
