import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogTitle } from "@mui/material";
import { Stack } from "@mui/system";
import { SaveCancelStack } from "components/buttons/buttonStack";

import {
  CreateWorkExtraInput,
  CreateWorkExtraInputSchema,
  CreateWorkMatInput,
  CreateWorkMatInputSchema,
  StockItemType,
  useAddWorkExtraMutation,
  useAddWorkMatMutation,
  WorkElementType,
} from "graphql/generated";
import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";

import {
  CreateWorkGlassInput,
  CreateWorkGlassInputSchema,
  useAddWorkGlassMutation,
} from "graphql/generated";

import { CreateWorkMatForm } from "components/forms/createWorkMatForm";
import { SelectStockItemAutoComplete } from "components/forms/formElements/selectStockItemAutocomplete";
import { useUserUnits } from "components/util/unitConverter";
import {
  CreateWorkFrameInput,
  CreateWorkFrameInputSchema,
  useAddWorkFrameMutation,
} from "graphql/generated";
import { useSnackAlert } from "components/util/snackAlert";

// TODO: refactor

export const CreateWorkElementDialog = (props: {
  workItemID: string;
  onClose: () => void;
  open: boolean;
  workElementType: WorkElementType;
}) => {
  switch (props.workElementType) {
    case WorkElementType.WorkFrame:
      return (
        <CreateWorkFrameDialog
          workItemID={props.workItemID}
          onFinish={props.onClose}
          open={props.open}
        />
      );
    case WorkElementType.WorkGlass:
      return (
        <CreateWorkGlassDialog
          workItemID={props.workItemID}
          onFinish={props.onClose}
          open={props.open}
        />
      );
    case WorkElementType.WorkExtra:
      return (
        <CreateWorkExtraDialog
          workItemID={props.workItemID}
          onFinish={props.onClose}
          open={props.open}
        />
      );
    case WorkElementType.WorkMat:
      return (
        <CreateWorkMatDialog
          workItemID={props.workItemID}
          onFinish={props.onClose}
          open={props.open}
        />
      );
  }
};

const CreateWorkExtraDialog = (props: {
  workItemID: string;
  onFinish: () => void;
  open: boolean;
}) => {
  const [addWorkExtraMutation, { loading }] = useAddWorkExtraMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onCompleted: () => {
      props.onFinish();
      form.reset();
    },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });
  const form = useForm<CreateWorkExtraInput>({
    mode: "onChange",
    resolver: yupResolver(CreateWorkExtraInputSchema()),
    defaultValues: {
      workItemID: props.workItemID,
    },
  });

  function onFormSubmit(data: CreateWorkExtraInput) {
    addWorkExtraMutation({ variables: { input: data } });
    return false;
  }

  const { SnackAlert, showSnackAlert } = useSnackAlert();

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Add extra</DialogTitle>
        <FormContainer formContext={form}>
          <Stack>
            <SelectStockItemAutoComplete stockItemType={StockItemType.Extra} />

            <SaveCancelStack
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onFinish}
              loading={loading}
              disabled={!form.formState.isValid}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};

const CreateWorkFrameDialog = (props: {
  workItemID: string;
  onFinish: () => void;
  open: boolean;
}) => {
  const [addWorkFrameMutation, { loading }] = useAddWorkFrameMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onCompleted: () => {
      props.onFinish();
      form.reset();
    },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });
  const form = useForm<CreateWorkFrameInput>({
    mode: "onChange",
    resolver: yupResolver(CreateWorkFrameInputSchema()),
    defaultValues: {
      workItemID: props.workItemID,
    },
  });
  function onFormSubmit(data: CreateWorkFrameInput) {
    addWorkFrameMutation({ variables: { input: data } });
    return false;
  }
  const { SnackAlert, showSnackAlert } = useSnackAlert();

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Add frame</DialogTitle>
        <FormContainer formContext={form}>
          <Stack>
            <SelectStockItemAutoComplete stockItemType={StockItemType.Frame} />

            <SaveCancelStack
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onFinish}
              loading={loading}
              disabled={!form.formState.isValid}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};

const CreateWorkGlassDialog = (props: {
  workItemID: string;
  onFinish: () => void;
  open: boolean;
}) => {
  const [addWorkGlassMutation, { loading }] = useAddWorkGlassMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onCompleted: () => {
      props.onFinish();
      form.reset();
    },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });
  const form = useForm<CreateWorkGlassInput>({
    mode: "onChange",
    resolver: yupResolver(CreateWorkGlassInputSchema()),
    defaultValues: {
      workItemID: props.workItemID,
    },
  });
  function onFormSubmit(data: CreateWorkGlassInput) {
    addWorkGlassMutation({ variables: { input: data } });
    return false;
  }
  const { SnackAlert, showSnackAlert } = useSnackAlert();

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Add glass</DialogTitle>
        <FormContainer formContext={form}>
          <Stack>
            <SelectStockItemAutoComplete stockItemType={StockItemType.Glass} />
            <SaveCancelStack
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={props.onFinish}
              loading={loading}
              disabled={!form.formState.isValid}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};

const CreateWorkMatDialog = (props: {
  workItemID: string;
  onFinish: () => void;
  open: boolean;
}) => {
  // GraphQL mutation
  const [createWorkMatMutation, { loading }] = useAddWorkMatMutation({
    // context: { permission: AuthPermission.EditWorkItems },
    onCompleted: () => {
      form.reset();
      props.onFinish();
    },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });

  // Change validation schema to use user units
  const { toSystemUnits } = useUserUnits();

  const validationSchema = CreateWorkMatInputSchema();
  validationSchema.withMutation(() => {
    validationSchema.fields.top =
      validationSchema.fields.top.transform(toSystemUnits);
    validationSchema.fields.side =
      validationSchema.fields.side.transform(toSystemUnits);
    validationSchema.fields.bottom =
      validationSchema.fields.bottom.transform(toSystemUnits);
  });

  // React hook form
  const form = useForm<CreateWorkMatInput>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      workItemID: props.workItemID,
    },
  });

  // Form submit
  function onFormSubmit(data: CreateWorkMatInput) {
    createWorkMatMutation({ variables: { input: data } });
    return false;
  }

  // Cancel button
  function onCancel() {
    props.onFinish();
    form.reset();
  }
  const { SnackAlert, showSnackAlert } = useSnackAlert();

  return (
    <>
      <Dialog open={props.open}>
        <DialogTitle>Add mat</DialogTitle>
        <FormContainer formContext={form}>
          <Stack>
            <CreateWorkMatForm />
            <SaveCancelStack
              onSave={form.handleSubmit(onFormSubmit)}
              onCancel={onCancel}
              loading={loading}
              //disabled={!form.formState.isValid}
            />
          </Stack>
        </FormContainer>
      </Dialog>
      <SnackAlert />
    </>
  );
};
