import { useSnackAlert } from "components/util/snackAlert";
import { StockItemType, useListStockItemsQuery } from "graphql/generated";
import { AutocompleteElement } from "react-hook-form-mui";

// TODO: Show price in autocomplete

export const SelectStockItemAutoComplete = (props: {
  stockItemType: StockItemType;
}) => {
  const { SnackAlert, showSnackAlert } = useSnackAlert();
  const { data, loading } = useListStockItemsQuery({
    // context: { permission: AuthPermission.ReadStockItems },
    variables: {
      input: {
        type: props.stockItemType,
        active: true,
      },
    },
    onError: (error) => {
      showSnackAlert({ error });
    },
  });

  const name = props.stockItemType.toLowerCase() + "ID";

  const label = props.stockItemType.toLowerCase();

  const getOptionLabel = (option: any) => {
    switch (props.stockItemType) {
      case StockItemType.Frame:
        return option.code + " " + option.name;
      case StockItemType.Mat:
        return option.code + " " + option.name;
      case StockItemType.Glass:
        return option.name;
      case StockItemType.Extra:
        return option.name;
    }
  };

  return (
    <>
      <AutocompleteElement
        name={name}
        label={label}
        loading={loading}
        options={data?.stockItems || []}
        matchId
        autocompleteProps={{
          getOptionLabel: getOptionLabel,
        }}
      />
      <SnackAlert />
    </>
  );
};
